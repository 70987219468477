.slider-box{
  min-height: 86px;
}

.irs-bar,
.irs-bar-edge,
.irs-line-left,
.irs-line-mid,
.irs-line-right,
.irs-slider {
  background-image: url(../assets/img/theme/vendor/ionrangeslider/img/sprite-skin-flat.png);
  background-repeat: repeat-x;
}