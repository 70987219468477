#map-bubbles {
  width: 100%;
  height: calc(100vh - 283px);
  font-size	: 11px;
}

/*
#chartdiv1 {
  background: #3f3f4f;
  color:#ffffff;
  width		: 100%;
  height		: 500px;
  font-size	: 11px;
}*/
