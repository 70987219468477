$panel-title-height: 44px;
$panel-heading-font-size: 16px;

.panel {
  @include scrollbars(.4em, rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0.7));
  color: $default-text;
  background-color: $default;
  border: none;
  border-radius: 5px;
  position: relative;
  margin-bottom: 24px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
  &.animated {
    animation-duration: 0.5s;
  }
  &.small-panel {
    height: $small-panel-height;
  }
  &.xsmall-panel {
    height: $xsmall-panel-height;
  }
  &.medium-panel {
    height: $medium-panel-height;
  }
  &.xmedium-panel {
    height: $extra-medium-panel-height;
  }
  &.large-panel {
    height: $large-panel-height;
  }
  &.viewport100 {
    height: calc(100vh - 218px);
  }

  &.with-scroll {
    .panel-body {
      height: calc(100% - #{$panel-title-height});
      overflow-y: auto;
    }
  }
}

.panel.panel-blur {
  $blurredBgUrl: $images-root + 'blur-bg-blurred.jpg';
  background: url($blurredBgUrl);
  transition: none;
  background-attachment: fixed;
}

.panel {
  > .panel-body {
    padding: 15px 22px;
    height: 100%;
  }
  > .panel-heading {
    color: $default-text;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  > .panel-footer {
    color: $default-text;
  }
}

.panel-heading, .panel-footer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.12);
  height: $panel-title-height;
  font-size: $panel-heading-font-size;
  $vertical-padding: ($panel-title-height - $panel-heading-font-size) / 2;
  padding: $vertical-padding 22px;
}

.panel-title {
  font-weight: $font-normal;
  text-transform: uppercase;
  opacity: 0.9;
}

.panel-default {
  > .panel-heading {
    color: $bootstrap-panel-text;
  }
  > .panel-footer {
    color: $bootstrap-panel-text;
  }
}


.panel-primary {
  > .panel-heading {
    background-color: $primary-bg;
    border-color: $primary-bg;
  }
}

.panel-success {
  > .panel-heading {
    background-color: $success-bg;
    border-color: $success-bg;
  }
}

.panel-info {
  > .panel-heading {
    background-color: $info-bg;
    border-color: $info-bg;
  }
}

.panel-warning {
  > .panel-heading {
    background-color: $warning-bg;
    border-color: $warning-bg;
  }
}

.panel-danger {
  > .panel-heading {
    background-color: $danger-bg;
    border-color: $danger-bg;
  }
}

.bootstrap-panel{
  background-color: $bootstrap-panel-bg;
  .panel-body{
    color: $bootstrap-panel-text;
  }
}

.accordion-panel.panel {
  &.panel-primary,
  &.panel-success,
  &.panel-info,
  &.panel-warning,
  &.panel-danger {
    .panel-heading {
      p, div, span {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}

.panel-group .panel.accordion-panel {
  .panel-heading {
    border-bottom: 0;
  }
}

.panel-group .panel {
  .panel-heading {
    border-bottom: 1px solid #ddd;
  }
}

.p-with-code {
  line-height: 1.5em;
}

.contextual-example-panel {
  height: 120px;
}

.footer-panel {
  height: 142px;
}

.light-text {
  font-weight: $font-light;
}

/*
.panel-group .panel {
  border-radius: 0;
}*/
