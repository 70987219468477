/*
.panel-group {
  > .panel {
    > .panel-heading {
      padding: 0;

      > h4.panel-title {
        height: 50px;
        width: 100%;
        padding: 0;

        > a {
          display: block;
          padding: 15px 22px;
          width: 100%;
        }
      }
    }
  }

}

.panel-collapse {
  transition: height $default-animation-duration $default-animation-style;
  .panel-body {
    padding: 15px;
  }
}*/
