.tpl-skin-panel {
  width: 300px;
  .tpl-skin-option {
    padding: 10px;
    line-height: 83px;
    color: $default-text;
    &:hover {
      cursor: pointer;
      background-color: $primary;
    }
    + .tpl-skin-option {
      border-top: 1px solid $border-light;
    }
  }
  .skin-thumbnail {
    width: 100%;
  }
}