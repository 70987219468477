.show-grid div[class^=col-]{
  padding: 10px;
  box-sizing: border-box;
  div {
    color: $default-text;
    text-align: center;
    font-size: 18px;
    background-color: rgba($default-text, 0.3);
    padding: 12px 5px;
  }
}

.grid-h{
  margin-top: 40px;
  margin-bottom: 0;
  &:first-child{
    margin-top: 0;
  }
}
