.toast {
  background-color: $primary;
}

.toast-success {
  background-color: $success;
}

.toast-error {
  background-color: $danger;
}

.toast-info {
  background-color: $info;
}

.toast-warning {
  background-color: $warning;
}

#toast-container.toast-top-full-width > div, #toast-container.toast-bottom-full-width > div {
  margin: 5px auto;
}
