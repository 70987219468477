.panel.popular-app {
  &>.panel-body {
    padding: 0;
  }

  .popular-app-img-container {
    position: relative;
    padding: 30px 0;
    height: 260px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    .popular-app-img {
      width: 260px;
      position: absolute;
      transform: translateY(-50%) translate(-50%);
      top: 50%;
      left: 50%;

      .logo-text {
        display: inline-block;
        margin: 10px;
        font-size: 26px;
        color: $default-text;
      }
    }
  }
  .popular-app-cost {
    font-size: 20px;
    padding: 20px 22px;
    border-bottom: 1px solid $border-light;
    border-top: 1px solid $border-light;
  }
  .popular-app-info {
    padding: 20px 22px;
    font-size: 20px;
    text-align: center;
    .info-label {
      font-size: 12px;
    }
  }
  .row {
    margin: 0;
    > div {
      padding: 0;
    }
  }
}

.blur-theme .panel.popular-app .popular-app-img-container {
  background: rgba(0, 0, 0, 0.5);
}