.tabset-group {
  > div + div {
    margin-top: 15px;
  }
}


.panel.tabs-panel{
  .panel-body{
    padding: 0;
  }
  .dropdown-menu{
    min-width: 132px;
    top: auto;
    border: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &.with-scroll .panel-body{
    height: 100%;
  }
}

.xsmall-panel {
  .nav-tabs, .tab-content{
    height: 100%;
  }
}
.inline-icon{
  display: inline-block;
  margin: 20px 5px;
}
.panel.horizontal-tabs {
  .tab-content{
    height: calc(100% - 40px);
  }
}

