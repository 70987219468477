#cd-timeline {
  margin-top: 15px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #d7e4ed;
  }
}

.cd-timeline-block {
  position: relative;
  margin: 2em 0;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@media only screen and (min-width: $resXL) {
  .cd-timeline-img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    animation: cd-bounce-1 0.6s;
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  background: $primary;
  padding: 1em;
  border-radius: 5px;
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  h2 {
    color: #303e49;
    margin: 0;
    font-size: 18px;
  }

  .cd-read-more, .cd-date {
    font-size: 13px;
    display: inline-block;
  }

  p {
    font-size: 13px;
    margin-bottom: 0;
  }

  .cd-read-more {
    float: right;
    padding: .8em 1em;
    background: #acb7c0;
    color: white;
    border-radius: 0.25em;
  }

  .cd-date {
    float: left;
    padding: .8em 0;
    opacity: .7;
  }

  &:before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid white;
  }
}

@mixin cd-timeline-color($color) {
  background: $color;
}

@mixin cd-timeline-left-color($color) {
  &:before {
    border-left-color: $color;
  }
}

@mixin cd-timeline-right-color($color) {
  &::before {
    border-right-color: $color;
  }
}

.cd-timeline-content.warning {
  @include cd-timeline-color(rgba($warning, 0.2));
}
.cd-timeline-content.danger {
  @include cd-timeline-color(rgba($danger, 0.2));
}
.cd-timeline-content.primary {
  @include cd-timeline-color(rgba($primary, 0.2));
}

.no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: $border;
}

@media only screen and (min-width: $resXL) {

  #cd-timeline::before {
    left: 50%;
    margin-left: -2px;
  }

  .cd-timeline-block {
    margin: 4em 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 42%;
    &::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: white;
    }

    .cd-read-more {
      float: left;
    }
    .cd-date {
      position: absolute;
      width: 100%;
      left: 137%;
      top: 6px;
      font-size: 16px;
    }
  }

  .cd-timeline-content.warning {
    @include cd-timeline-left-color(rgba($warning, 0.2));
  }
  .cd-timeline-content.danger {
    @include cd-timeline-left-color(rgba($danger, 0.2));
  }
  .cd-timeline-content.primary {
    @include cd-timeline-left-color(rgba($primary, 0.2));
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }

  .cd-timeline-block:nth-child(even) {
    .cd-timeline-content {
      &::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: white;
      }

      &.cd-timeline-content.warning {
        @include cd-timeline-right-color(rgba($warning, 0.2));
      }
      &.cd-timeline-content.danger {
        @include cd-timeline-right-color(rgba($danger, 0.2));
      }
      &.cd-timeline-content.primary {
        @include cd-timeline-right-color(rgba($primary, 0.2));
      }
    }
  }

  .cd-timeline-block:nth-child(even) {
    .cd-timeline-content {
      .cd-read-more {
        float: right;
      }
      .cd-date {
        left: auto;
        right: 137%;
        text-align: right;
      }
    }
  }

  .cssanimations .cd-timeline-content {
    &.is-hidden {
      visibility: hidden;
    }
    &.bounce-in {
      visibility: visible;
      animation: cd-bounce-2 0.6s;
    }
  }
}

@media only screen and (min-width: $resXL) {
  /* inverse bounce effect on even content blocks */
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    animation: cd-bounce-2-inverse 0.6s;
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0);
  }
}