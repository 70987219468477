.ba-wizard-progress {
  margin-top: 10px;
  height: 10px;
}

.ba-wizard-pager {
  .previous {
    float: left;
  }
  .next {
    float: right;
  }
}

.ba-wizard-navigation-container {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 15px 0;
  .ba-wizard-navigation {
    cursor: pointer;
    display: table-cell;
    line-height: 1;
    text-align: center;
    font-weight: $font-light;
    font-size: 18px;
    opacity: 0.7;
    &.active {
      opacity: 0.95;
    }
  }
}